import { useMsal } from "@azure/msal-react";
import NewWindow from "react-new-window";
import { Button, Container, Nav, NavItem, NavLink, Table } from "reactstrap";
import { ConfigData } from "./config/ConfigData";
import { FormContext } from "../context/FormContext";
import { useContext, useEffect, useRef, useState } from "react";
import ConfigTab from "./ConfigTab";
import ConfigSearch from "./ConfigSearch";
import { getSpecificReference, translateConfigToView, viewConfig } from "../services/apiService";
import Spinner from "./Spinner";
import { checkReduxNetworkPopulated, clearReduxNetwork } from "../app/networkSlice";
import { useDispatch, useSelector } from "react-redux";
import WarningModal from "./WarningModal";
import { ToolContext } from "../context/ToolContext";
import { clearHistoryAction } from "../app/undoable";
import { store } from "../app/store";
import { hasOverrides } from "../utils/configurationFunctions";
import { resetNetworkToolState } from "../utils/networkFunctions";
import useBearerToken from "../utils/useBearerToken";

const ConfigWindow = ({ onUnload, data, theme }) => {
  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  const { REACT_APP_THEME } = process.env;

  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const dispatchRedux = useDispatch();

  const { reference, rawConfigCompressed, localOverrideConfig } = formState;

  const [configData, setConfigData] = useState();
  const [activePage, setActivePage] = useState(configData?.pages[0]);
  const [optionSearchValue, setOptionSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentConfigIsApplied, setCurrentConfigIsApplied] = useState(true);
  const [showChangeConfigWarning, setShowChangeConfigWarning] = useState();
  const [warningMessage, setWarningMessage] = useState("");
  const [currentVersion, setCurrentVersion] = useState({});
  const containerRef = useRef(null);

  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

  let isRequesting = false;
  const list = data;
  if (
    !list.find(
      (p) =>
        p.clientName === reference.version.client && p.versionNumber === reference.version.number,
    )
  ) {
    if (reference.version.client === "AUTO") {
      list.unshift({
        clientName: reference.version.client,
        versionNumber: reference.version.number,
        description: reference.version.description,
        releaseDate: reference.version.releaseDate,
      });
    } else {
      list.push({
        clientName: reference.version.client,
        versionNumber: reference.version.number,
        description: reference.version.description,
        releaseDate: reference.version.releaseDate,
      });
    }
  }

  useEffect(() => {
    if (isRequesting) {
      return;
    }
    if (
      currentVersion.client !== reference.version.client ||
      currentVersion.number !== reference.version.number
    ) {
      load({
        clientName: reference.version.client,
        versionNumber: reference.version.number,
      });
    }
  }, [reference.version.client, reference.version.number]);

  const toggle = (page) => {
    if (activePage !== page) {
      setActivePage(page);
    }
  };

  const apply = async (row, confirmed) => {
    if (!row) {
      setShowChangeConfigWarning();
      return;
    }
    if (!confirmed) {
      let _warningMessage = "";
      if (isReduxNetworkPopulated) {
        _warningMessage = `Configuration cannot be altered for an open design. Start a new design with these settings?`;
      }
      if (hasOverrides(localOverrideConfig)) {
        _warningMessage += `  Locally-defined configuration will be discarded if you apply these settings.`;
      }
      if (_warningMessage) {
        setWarningMessage(_warningMessage);
        setShowChangeConfigWarning(row);
        return;
      }
    }

    setIsLoading(true);
    setShowChangeConfigWarning();

    if (isReduxNetworkPopulated) {
      dispatchRedux(clearReduxNetwork());
    }

    store.dispatch(clearHistoryAction());

    const _toolState = { ...toolState };
    resetNetworkToolState(_toolState);
    setToolState(_toolState);

    const token = await getToken(instance, accounts);
    getSpecificReference(token, row.clientName, row.versionNumber).then((p) => {
      dispatch({
        form: "reference",
        obj: p,
        type: "REPLACE_STATE",
      });
      dispatch({
        form: "localOverrideConfig",
        obj: null,
        type: "REPLACE_STATE",
      });
      setIsLoading(false);
    });
    setCurrentConfigIsApplied(true);
  };

  const getConfigData = async (clientName, versionNumber) => {
    const token = await getToken(instance, accounts);
    if (clientName === "AUTO") {
      return translateConfigToView(token, rawConfigCompressed.compressedData);
    }
    return viewConfig(token, clientName, versionNumber);
  };

  const isConfigLoaded = (row) =>
    reference.version.client === row.clientName && reference.version.number === row.versionNumber;

  const customiseViewConfig = (config) => {
    const fullAutoList = config.cableTypes
      .flatMap((c) => {
        const autos = [];
        if (c.isMains && c.isUnderground) {
          autos.push({ cableClass: "mains-underground", name: c.cableType, autoSelect: false });
        }
        if (c.isMains && c.isOverhead) {
          autos.push({ cableClass: "mains-overhead", name: c.cableType, autoSelect: false });
        }
        if (c.isService && c.isUnderground) {
          autos.push({ cableClass: "service-underground", name: c.cableType, autoSelect: false });
        }
        if (c.isService && c.isOverhead) {
          autos.push({ cableClass: "service-overhead", name: c.cableType, autoSelect: false });
        }
        return autos;
      })
      .toSorted((a, b) => {
        const toSortString = (autoCable) =>
          `${autoCable.cableClass.replace("overhead", "x")}-${autoCable.name}`;
        return toSortString(a) > toSortString(b) ? 1 : -1;
      });

    fullAutoList.forEach((c) => {
      if (
        config.cableAutoSelects.find((c1) => c.cableClass === c1.cableClass && c.name === c1.name)
      ) {
        c.autoSelect = true;
      }
    });

    config.cableAutoSelects = fullAutoList;
    return config;
  };

  const load = (row) => {
    setIsLoading(true);
    isRequesting = true;
    getConfigData(row.clientName, row.versionNumber).then((p) => {
      const config = customiseViewConfig(p);
      const c = new ConfigData(config);
      setCurrentConfigIsApplied(isConfigLoaded(row));
      setActivePage(c.pages[0]);
      setConfigData(c);
      setCurrentVersion({ client: row.clientName, number: row.versionNumber });
      setIsLoading(false);
      isRequesting = false;
    });
  };

  const getButton = (row) => {
    if (!currentVersion.client && isConfigLoaded(row)) {
      return null;
    }
    if (currentVersion.client === row.clientName && currentVersion.number === row.versionNumber) {
      const isCurrent = isConfigLoaded(row);
      if (!isCurrent) {
        return (
          <Button size="sm" color="success" className="ml-2" onClick={() => apply(row)}>
            Apply
          </Button>
        );
      }
      return null;
    }
    return (
      <Button size="sm" color="info" className="ml-2" onClick={() => load(row)}>
        View
      </Button>
    );
  };

  return (
    <NewWindow features={{ width: 1200, height: 600 }} onUnload={() => onUnload(false)} id="popup">
      {showChangeConfigWarning && (
        <WarningModal
          ref={containerRef}
          item={showChangeConfigWarning}
          action={(p) => apply(p, true)}
          dismissAction={false}
          msg={warningMessage}
          yesLabel="Reset"
          dismissLabel="Cancel"
        />
      )}
      <div ref={containerRef}>
        <Container fluid className="report">
          <div className="mx-4">
            <h2 className="mb-4">View Configuration Properties</h2>
            <span>CurrentConfigIsApplied {currentConfigIsApplied.toString()}</span>
            <div className="row">
              <Table
                id="version-table"
                className={`table-sm table-custom table-custom-${REACT_APP_THEME} table-sortable`}
              >
                <thead>
                  <tr className="mb-4">
                    <th>Version</th>
                    <th>Description</th>
                    <th>Release Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr
                      className={isConfigLoaded(row) ? "active" : ""}
                      key={`${row.clientName} ${row.versionNumber}`}
                    >
                      <td>
                        {row.clientName} v{row.versionNumber}
                      </td>
                      <td>
                        {row.description}
                        {isConfigLoaded(row) && (
                          <span className="badge badge-success">Current</span>
                        )}
                      </td>
                      <td>{row.releaseDate}</td>
                      <td>{getButton(row)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            {isLoading ? (
              <Spinner>Loading configuration</Spinner>
            ) : (
              <>
                <h3 className="mb-3">
                  {currentVersion.client} v{currentVersion.number}
                </h3>
                <div className="row">
                  <div className="col-md-2" style={{ fontSize: "0.8rem" }}>
                    <ConfigSearch
                      optionSearchValue={optionSearchValue}
                      setOptionSearchValue={setOptionSearchValue}
                    />
                    <Nav className="clickable-nav-links" vertical={true}>
                      {configData?.pages
                        .filter(
                          (p) =>
                            optionSearchValue.trim() === "" || p.matchesSearch(optionSearchValue),
                        )
                        .map((p) => (
                          <div>
                            <NavItem key={p?.title}>
                              <NavLink
                                className={activePage?.title === p?.title ? "active" : ""}
                                onClick={() => toggle(p)}
                              >
                                {p.title}
                              </NavLink>
                            </NavItem>
                          </div>
                        ))}
                    </Nav>
                  </div>
                  <div className="col-md-10">
                    {activePage && (
                      <ConfigTab
                        page={activePage}
                        theme={theme}
                        currentConfigIsApplied={currentConfigIsApplied}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    </NewWindow>
  );
};

export default ConfigWindow;
