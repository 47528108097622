import { useContext } from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SpinnerIcon from "./SpinnerIcon";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import BtnGrp from "./BtnGrp";
import { BLUE_TICK } from "../constants/colors";
import { setLocalStorageValue } from "../utils/useLocalStorage";
import { getResultProperties } from "../utils/referenceFunctions";
import {
  showCableLabels,
  showCableLabelIds,
  isColorBlindEnabled,
  isStatusStylingEnabled,
  isLCTIndicatorEnabled,
  isIDNOBoundariesEnabled,
  isPopupResultsEnabled,
  showMapBackground,
  showCascadingRagColors,
  iconSize,
  ratingType,
} from "../app/settingsSlice";
import { clearResults } from "../app/networkSlice";
import { useDispatch, useSelector } from "react-redux";

const CheckboxBtn = () => {
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const { toolState, setToolState } = useContext(ToolContext);
  const { toolbar } = toolState;

  const dispatch = useDispatch();
  const cableLabels = useSelector((state) => state.settings.showCableLabels);
  const cableLabelIds = useSelector((state) => state.settings.showCableLabelIds);

  const colourBlindEnabled = useSelector((state) => state.settings.isColorBlindEnabled);
  const statusStyling = useSelector((state) => state.settings.isStatusStylingEnabled);
  const lctIndicator = useSelector((state) => state.settings.isLCTIndicatorEnabled);
  const idnoBoundaries = useSelector((state) => state.settings.isIDNOBoundariesEnabled);
  const popupResults = useSelector((state) => state.settings.isPopupResultsEnabled);
  const showTiles = useSelector((state) => state.settings.showMapBackground);
  const cascadingRAGColours = useSelector((state) => state.settings.showCascadingRagColors);
  const size = useSelector((state) => state.settings.iconSize);
  const rating = useSelector((state) => state.settings.ratingType);

  const setIconSize = (size) => {
    dispatch(iconSize(size));
    setLocalStorageValue("iconSize", size);
  };

  const setCableRating = (newRating) => {
    dispatch(ratingType(newRating));
    setLocalStorageValue("ratingType", newRating);
    if (rating !== newRating) {
      dispatch(clearResults(getResultProperties(clientSettings)));
    }
  };

  const setMode = () => {
    const mode = popupResults ? "select" : "select-results";
    const itemIndex = toolbar[0].items.findIndex((item) => item.class === mode);

    setToolState({
      mode,
      selectedTool: [itemIndex, 0, 0, 0, 0],
    });
  };

  const {
    DebugEnabled,
    ColourBlindEnabled,
    StatusStylingEnabled,
    LCTIndicatorEnabled,
    MapTilesEnabled,
    RAGCablesEnabled,
    CascadingRAGOptionEnabled,
    LayerIDNOBoundaryEnabled,
    AssessmentResultsPopupEnabled,
  } = clientSettings.Features;

  const { MapTiles } = clientSettings.Urls.EndPoints;

  return (
    <>
      <ListGroupItem className="bg-dark text-uppercase rounded">Map</ListGroupItem>
      <ListGroupItem className="list-group-item-btn-grp d-flex justify-content-between align-items-center">
        <span className="mr-4">Icon Size</span>
        <BtnGrp
          arr={[
            { id: 1, name: "Small" },
            { id: 2, name: "Medium" },
            { id: 3, name: "Large" },
          ]}
          value={size}
          setValue={setIconSize}
        />
      </ListGroupItem>
      <ListGroupItem
        tag="a"
        action
        className="d-flex justify-content-between align-items-center text-light"
        onClick={() => {
          dispatch(showCableLabels());
          setLocalStorageValue("cableLabels", !cableLabels);
        }}
      >
        <span className="mr-4">
          Show Cable Labels
          {cableLabels && (
            <div className="text-warning">
              <FontAwesomeIcon icon={faInfoCircle} /> May impact performance of large networks
            </div>
          )}
        </span>
        <SpinnerIcon flag={cableLabels} showSpinner={false} color={BLUE_TICK} />
      </ListGroupItem>
      {DebugEnabled && cableLabels && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(showCableLabelIds());
            setLocalStorageValue("cableLabelIds", !cableLabelIds);
          }}
        >
          <span className="mr-4">Show Cable Label Ids</span>
          <SpinnerIcon flag={cableLabelIds} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {ColourBlindEnabled && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(isColorBlindEnabled());
            setLocalStorageValue("colourBlindEnabled", !colourBlindEnabled);
          }}
        >
          <span className="mr-4">High Colour Contrast</span>
          <SpinnerIcon flag={colourBlindEnabled} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {StatusStylingEnabled && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(isStatusStylingEnabled());
            setLocalStorageValue("statusStyling", !statusStyling);
          }}
        >
          <span className="mr-4">Show Status Styling</span>
          <SpinnerIcon flag={statusStyling} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {LCTIndicatorEnabled && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(isLCTIndicatorEnabled());
            setLocalStorageValue("lctIndicator", !lctIndicator);
          }}
        >
          <span className="mr-4">Show LCT Indicators</span>
          <SpinnerIcon flag={lctIndicator} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {LayerIDNOBoundaryEnabled && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(isIDNOBoundariesEnabled());
            setLocalStorageValue("idnoBoundaries", !idnoBoundaries);
          }}
        >
          <span className="mr-4">Show IDNO Boundaries</span>
          <SpinnerIcon flag={idnoBoundaries} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {AssessmentResultsPopupEnabled && (
        <ListGroupItem
          tag="a"
          action
          className="d-flex justify-content-between align-items-center text-light"
          onClick={() => {
            dispatch(isPopupResultsEnabled());
            setLocalStorageValue("popupResults", !popupResults);
            setMode();
          }}
        >
          <span className="mr-4">Popup Results</span>
          <SpinnerIcon flag={popupResults} showSpinner={false} color={BLUE_TICK} />
        </ListGroupItem>
      )}
      {((MapTilesEnabled && MapTiles) || RAGCablesEnabled) && (
        <>
          <ListGroupItem className="bg-dark text-uppercase rounded">Layers</ListGroupItem>
          {MapTilesEnabled && MapTiles && (
            <ListGroupItem
              tag="a"
              action
              className="d-flex justify-content-between align-items-center text-light"
              onClick={() => {
                dispatch(showMapBackground());
                setLocalStorageValue("showTiles", !showTiles);
              }}
            >
              <span className="mr-4">Show Map Background</span>
              <SpinnerIcon flag={showTiles} showSpinner={false} color={BLUE_TICK} />
            </ListGroupItem>
          )}
          {RAGCablesEnabled && CascadingRAGOptionEnabled && (
            <ListGroupItem
              tag="a"
              action
              className="d-flex justify-content-between align-items-center text-light"
              onClick={() => {
                dispatch(showCascadingRagColors());
                setLocalStorageValue("cascadingRAGColours", !cascadingRAGColours);
              }}
            >
              <span className="mr-4">RAG Cascading Colours</span>
              <SpinnerIcon flag={cascadingRAGColours} showSpinner={false} color={BLUE_TICK} />
            </ListGroupItem>
          )}
        </>
      )}
      <ListGroupItem className="bg-dark text-uppercase rounded">Parameters</ListGroupItem>
      <ListGroupItem className="list-group-item-btn-grp d-flex justify-content-between align-items-center">
        <span className="mr-4">Rating</span>
        {formState.reference.cables && (
          <BtnGrp
            arr={formState.reference.cables.ratings || []}
            value={rating}
            setValue={setCableRating}
          />
        )}
      </ListGroupItem>
    </>
  );
};

export default CheckboxBtn;
