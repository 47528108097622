import { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListGroupItem, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import WarningModal from "./WarningModal";
import { v4 as uuid } from "uuid";
import rfdc from "rfdc";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { removePolesForCable } from "../utils/poleFunctions";
import { removeLinkBoxCable } from "../utils/linkBoxFunctions";
import {
  clearResults,
  getAllGeometryAssets,
  findAll,
  deleteWelder,
  deleteMotor,
  deletePointOfConnection,
  deleteTransformer,
  deleteGroupedConnection,
  deleteNode,
  deleteConnectionPoint,
  deleteCable,
} from "../app/networkSlice";

import { GroupedConnection } from "../model/viewModel/groupedConnection";
import {
  removeGroupedConnection,
  refineRingfencedAssets,
  removePointOfConnection,
} from "../utils/ringfenceFunctions";
import { getRingfencedNetworkAssets, getRingfencedTypes } from "./RingfenceTool";
import GroupAsset from "./GroupAsset";
import { isConnectionPoint, isPointOfConnection } from "../utils/networkFunctions";
import { getResultProperties } from "../utils/referenceFunctions";
import StatusUpdate from "./group-update/StatusUpdate";
import {
  conductorTypes,
  lctTypes,
  propertyTypes,
  transformerTypes,
  nodeTypes,
  groupedTypes,
} from "../constants/groupUpdateTypes";
import {
  applyStatusChange,
  reviewStatusChange,
  shouldShowLinkBoxStatusForm,
  shouldShowPotEndStatusForm,
  shouldShowPoleStatusForm,
  shouldShowStatusForm,
} from "./group-update/StatusUpdateActions";
import StatusNodeUpdate from "./group-update/StatusNodeUpdate";
import PhaseUpdate from "./group-update/PhaseUpdate";
import CableTypeUpdate from "./group-update/CableTypeUpdate";
import ConsumerUpdate from "./group-update/ConsumerUpdate";
import SubConsumerUpdate from "./group-update/SubConsumerUpdate";
import {
  applyPhaseChange,
  refreshNumberOfPhasesWarning,
  shouldShowPhasesForm,
} from "./group-update/PhaseUpdateActions";
import {
  applyCableTypeChange,
  refreshCableTypeWarning,
  shouldShowCableTypeForm,
} from "./group-update/CableTypeUpdateActions";
import { applyConsumerChange, shouldShowConsumerForm } from "./group-update/ConsumerUpdateActions";
import {
  applySubGroupChange,
  shouldShowSubGroupForm,
} from "./group-update/SubConsumerUpdateActions";
import {
  applyLinkBoxChange,
  applyPoleChange,
  applyPotEndChange,
} from "./group-update/NodeUpdateActions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";

const clone = rfdc();

export const getNetworkAsset = (e, allAssets) => {
  let networkAsset = {};

  allAssets.forEach((asset) => {
    if (asset.asset.id === e.id) {
      networkAsset = {
        id: asset.asset.id,
        type: asset.type,
      };
    }
  });

  return networkAsset;
};

const GroupUpdate = () => {
  const [pendingAssetRefresh, setPendingAssetRefesh] = useState(false);
  const [selected, setSelectedValue] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [showUpdatedMessage, setShowUpdatedMessage] = useState(false);
  const [numberOfPhases, setNumberOfPhases] = useState();
  const [numberOfPhasesWarning, setNumberOfPhasesWarning] = useState(false);
  const [status, setStatus] = useState();
  const [poleStatus, setPoleStatus] = useState();
  const [linkBoxStatus, setLinkBoxStatus] = useState();
  const [potEndStatus, setPotEndStatus] = useState();
  const [cableType, setCableType] = useState();
  const [cableTypeWarning, setCableTypeWarning] = useState(false);
  const [subGroups, setSubGroups] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [minimisedNumberOfPhases, setMinimisedNumberOfPhases] = useState(true);
  const [minimisedStatus, setMinimisedStatus] = useState(true);
  const [minimisedPoleStatus, setMinimisedPoleStatus] = useState(true);
  const [minimisedLinkBoxStatus, setMinimisedLinkBoxStatus] = useState(true);
  const [minimisedPotEndStatus, setMinimisedPotEndStatus] = useState(true);
  const [minimisedCableType, setMinimisedCableType] = useState(true);
  const [minimisedSubGroup, setMinimisedSubGroup] = useState(true);
  const [minimisedConsumers, setMinimisedConsumers] = useState(true);

  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings, reference, localOverrideConfig } = formState;
  const {
    mode,
    ringfenced,
    ringfencedFiltered,
    ringfencedTypes,
    ringfenceUpdated,
    drawBoundary,
    groupClick,
    keyDown,
  } = toolState;

  const dispatchRedux = useDispatch();

  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));
  const allAssets = useSelector((state) => findAll(state));
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const cables = useSelector((state) => state.network.present.cables);

  const ohWhitelist = reference.newOHCableTypeWhiteList?.split(",") ?? [];
  const ugWhitelist = reference.newUGCableTypeWhiteList?.split(",") ?? [];

  useEffect(() => {
    if (
      pendingAssetRefresh ||
      minimisedCableType ||
      !shouldShowCableTypeForm(ringfencedTypes, selected) ||
      cableType === "auto" ||
      !clientSettings.Features.NewExistingStatusEnabled
    ) {
      if (cableTypeWarning) {
        setCableTypeWarning(false);
      }
      return;
    }
    refreshCableTypeWarning(
      ringfencedFiltered,
      ohWhitelist,
      ugWhitelist,
      cableType,
      cableTypeWarning,
      setCableTypeWarning,
      status,
    );
  }, [cableType, selected, pendingAssetRefresh, minimisedCableType, status]);

  useEffect(() => {
    if (
      pendingAssetRefresh ||
      minimisedNumberOfPhases ||
      !shouldShowPhasesForm(ringfencedTypes, selected) ||
      !numberOfPhases
    ) {
      if (numberOfPhasesWarning) {
        setNumberOfPhasesWarning(false);
      }
      return;
    }
    refreshNumberOfPhasesWarning(
      ringfencedFiltered,
      reference,
      numberOfPhases,
      setNumberOfPhasesWarning,
    );
  }, [numberOfPhases, selected, pendingAssetRefresh, minimisedNumberOfPhases]);

  useEffect(() => {
    if ((mode === "select-group" || mode === "remove-group") && keyDown.key === "Delete") {
      deleteAssets(false, true);
    }
  }, [keyDown]);

  const clearState = () => {
    setMinimisedNumberOfPhases(true);
    setMinimisedStatus(true);
    setMinimisedPoleStatus(true);
    setMinimisedLinkBoxStatus(true);
    setMinimisedPotEndStatus(true);
    setMinimisedCableType(true);
    setMinimisedSubGroup(true);
    setMinimisedConsumers(true);
  };

  const setSelected = (newValue) => {
    if (newValue !== selected) {
      setSelectedValue(newValue);
      clearState();
    }
  };

  const hasPendingChange = () =>
    selected &&
    (numberOfPhases ||
      status ||
      poleStatus ||
      linkBoxStatus ||
      potEndStatus ||
      cableType ||
      subGroups.length > 0 ||
      consumers.length > 0);

  const confirmChanges = () => {
    const codes = reviewStatusChange(
      ringfencedFiltered,
      cableType,
      status,
      ohWhitelist,
      ugWhitelist,
    );
    if (codes.length > 0) {
      setConfirmationMessage(
        codes.map((p) => ({
          code: p.name,
          description: p.name.replace("{0}", p.count).replace("{1}", p.count !== 1 ? "s" : ""),
        })),
      );
    } else {
      applyChanges(true);
    }
  };

  const applyChanges = (isApproved) => {
    setConfirmationMessage(undefined);

    if (!isApproved) {
      return;
    }

    store.dispatch(startBatchAction());
    try {
      if (numberOfPhases) {
        applyPhaseChange(ringfencedFiltered, numberOfPhases, dispatchRedux);
      }
      if (status) {
        applyStatusChange(
          ringfencedFiltered,
          dispatchRedux,
          status,
          ohWhitelist,
          ugWhitelist,
          groupedConnections,
        );
      }
      if (poleStatus) {
        applyPoleChange(ringfencedFiltered, poleStatus, dispatchRedux);
      }
      if (potEndStatus) {
        applyPotEndChange(ringfencedFiltered, potEndStatus, dispatchRedux);
      }
      if (linkBoxStatus) {
        applyLinkBoxChange(ringfencedFiltered, linkBoxStatus, dispatchRedux);
      }
      if (cableType) {
        applyCableTypeChange(ringfencedFiltered, cableType, dispatchRedux);
      }
      if (subGroups.length > 0) {
        applySubGroupChange(ringfencedFiltered, subGroups, setSubGroups, dispatchRedux);
      }
      if (consumers.length > 0) {
        applyConsumerChange(ringfencedFiltered, consumers, setConsumers, dispatchRedux);
        if (nodeTypes.includes(selected) || selected === "Nodes") {
          setSelected("");
        }
      }
    } finally {
      store.dispatch(endBatchAction());
    }

    dispatchRedux(clearResults(getResultProperties(clientSettings)));
    setShowUpdatedMessage(true);
    const timeout = setTimeout(() => setShowUpdatedMessage(false), 2000);
    setPendingAssetRefesh(true);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  };

  useEffect(() => {
    setStatus(minimisedStatus ? undefined : "new");
  }, [minimisedStatus]);

  useEffect(() => {
    if (minimisedNumberOfPhases) {
      setNumberOfPhases(undefined);
    }
  }, [minimisedNumberOfPhases]);

  useEffect(() => {
    setPoleStatus(minimisedPoleStatus ? undefined : "new");
  }, [minimisedPoleStatus]);

  useEffect(() => {
    setLinkBoxStatus(minimisedLinkBoxStatus ? undefined : "new");
  }, [minimisedLinkBoxStatus]);

  useEffect(() => {
    setPotEndStatus(minimisedPotEndStatus ? undefined : "new");
  }, [minimisedPotEndStatus]);

  useEffect(() => {
    setCableType(minimisedCableType ? undefined : "auto");
  }, [minimisedCableType]);

  useEffect(() => {
    if (minimisedSubGroup) {
      setSubGroups([]);
    }
  }, [minimisedSubGroup]);

  useEffect(() => {
    if (minimisedConsumers) {
      setConsumers([]);
    }
  }, [minimisedConsumers]);

  useEffect(() => {
    setSelected("");
  }, [drawBoundary, groupClick]);

  useEffect(() => {
    if (selected === "" && mode === "select-group") {
      if (ringfencedTypes && ringfencedTypes.length > 0) {
        setSelected("All");
        refreshRingfencedAssets(true, "All");
      }
    }
  }, [ringfencedTypes, mode]);

  useEffect(() => {
    if (pendingAssetRefresh) {
      refreshRingfencedAssets(false);
      setPendingAssetRefesh(false);
    }
  }, [allGeometryAssets]);

  const getRingfencedAssets = () => {
    let counter = 0;
    const assets = [];
    if (!ringfencedTypes) {
      return { assets, counter };
    }

    ringfencedTypes.forEach((type) => {
      if (conductorTypes.includes(type.name)) {
        let parent = assets.find((a) => a.name === "Conductors");
        if (!parent) {
          parent = { name: "Conductors", count: 0, children: [] };
          assets.push(parent);
        }

        parent.children.push(type);
      } else if (transformerTypes.includes(type.name)) {
        let parent = assets.find((a) => a.name === "Transformers");
        if (!parent) {
          parent = { name: "Transformers", count: 0, children: [] };
          assets.push(parent);
        }
        parent.children.push(type);
      } else if (propertyTypes.includes(type.name)) {
        let parent = assets.find((a) => a.name === "Properties");
        if (!parent) {
          parent = { name: "Properties", count: 0, children: [] };
          assets.push(parent);
        }
        parent.children.push(type);
      } else if (lctTypes.includes(type.name)) {
        let parent = assets.find((a) => a.name === "LCT");
        if (!parent) {
          parent = { name: "LCT", count: 0, children: [] };
          assets.push(parent);
        }
        parent.children.push(type);
      } else if (nodeTypes.includes(type.name)) {
        let parent = assets.find((a) => a.name === "Nodes");
        if (!parent) {
          parent = { name: "Nodes", count: 0, children: [] };
          assets.push(parent);
        }
        parent.children.push(type);
      } else {
        assets.push(type);
      }
      counter += type.count;
    });

    return { assets, counter };
  };

  const allRingfencedAssets = getRingfencedAssets();
  const ringfencedAssets = allRingfencedAssets.assets;
  const ringfencedAssetCount = allRingfencedAssets.counter;

  const filterAssetList = (name, ringfenced, isParent = false) => {
    const _ringfenced = clone(ringfenced);
    let ringfencedFiltered = [];

    _ringfenced.forEach((asset) => {
      asset.styles.type === "groupedConnection"
        ? asset.groupedConnectionPoints.length < 1
          ? (asset.styles.name2 = "Node")
          : (asset.styles.name2 = "Grouped Connection")
        : (asset.styles.name2 = asset.styles.name);
    });

    if (isParent) {
      let types;
      switch (name) {
        case "Conductors":
          types = ["Overhead Line", "Underground Cable"];
          break;
        case "Transformers":
          types = transformerTypes;
          break;
        case "Properties":
          types = propertyTypes;
          break;
        case "LCT":
          types = lctTypes;
          break;
        case "Nodes":
          types = nodeTypes;
          break;
        default:
          types = [];
      }

      ringfencedFiltered = _ringfenced.filter((asset) =>
        asset.styles.name2 ? types.includes(asset.styles.name2) : types.includes(asset.styles.name),
      );
    } else {
      if (name === "Service End Node") {
        ringfencedFiltered = _ringfenced.filter(
          (asset) => asset.groupedConnectionPoints.length < 1 && asset.isServiceLeaf,
        );
      } else if (name === "All") {
        ringfencedFiltered = _ringfenced;
      } else if (conductorTypes.includes(name)) {
        const isMains = name.includes("Mains");
        const isService = name.includes("Service");

        ringfencedFiltered = _ringfenced.filter((asset) => {
          if (!asset.cableGroup) {
            return false;
          }

          let cableGroupMatch;
          if (isMains && asset.cableGroup.includes("mains")) {
            cableGroupMatch = true;
          } else if (isService && asset.cableGroup.includes("service")) {
            cableGroupMatch = true;
          }

          const styleName = name.replace("Mains", "").replace("Service", "").trim();
          const nameMatch = (asset.styles.name2 ?? asset.styles.name) === styleName;

          return nameMatch && cableGroupMatch;
        });
      } else if (name === "Node") {
        ringfencedFiltered = _ringfenced.filter((asset) => asset.styles.name === name);
      } else {
        ringfencedFiltered = _ringfenced.filter((asset) =>
          asset.styles.name2 ? asset.styles.name2 === name : asset.styles.name === name,
        );
      }
    }

    return ringfencedFiltered;
  };

  const filterAssets = (name, isParent = false) => {
    const _toolState = toolState;
    _toolState.ringfencedFiltered = filterAssetList(name, ringfenced, isParent);
    setSelected(name);
    dispatch({
      form: "groupedConnectionProperties",
      obj: new GroupedConnection(
        uuid(),
        "",
        [],
        "",
        reference.groundTypeOverrideDefaults.groundTypeOverride,
      ),
      type: "REPLACE_STATE",
    });
    return _toolState.ringfencedFiltered;
  };

  const deleteAssets = (refine = false, deleteAll = false) => {
    const _selected = deleteAll ? "All" : selected;
    let _ringfenced = ringfenced;
    let _ringfencedFiltered = ringfencedFiltered;

    if (refine) {
      _ringfenced = refineRingfencedAssets(cables, ringfenced);
      _ringfencedFiltered = _ringfenced;
    }

    store.dispatch(startBatchAction());
    try {
      _ringfencedFiltered.forEach((asset) => {
        if (isConnectionPoint(asset)) {
          dispatchRedux(clearResults(getResultProperties(clientSettings)));
          removeGroupedConnection(
            cables,
            _ringfenced,
            asset,
            _selected,
            groupedConnections,
            dispatchRedux,
          );
        } else if (isPointOfConnection(asset)) {
          dispatchRedux(clearResults(getResultProperties(clientSettings)));
          removePointOfConnection(reference, cables, asset, dispatchRedux);
          dispatchRedux(deletePointOfConnection(asset.id));
        } else {
          const networkAsset = getNetworkAsset(asset, allAssets);
          if (networkAsset.type === "cables") {
            removePolesForCable(networkAsset.id, allAssets, cables, dispatchRedux);
            removeLinkBoxCable(
              clientSettings,
              cables,
              groupedConnections,
              networkAsset.id,
              dispatchRedux,
            );
          }
          updateNetwork(networkAsset.id, networkAsset.type);
        }
      });
    } finally {
      store.dispatch(endBatchAction());
    }

    const _toolState = toolState;

    if (_selected === "All") {
      _toolState.ringfencedTypes = [];
    } else {
      _toolState.ringfencedTypes = ringfencedTypes.filter((asset) => asset.name !== _selected);
    }

    if (!_toolState.ringfencedTypes.length) {
      _toolState.drawBoundary = false;
      _toolState.ringfenced = [];
      _toolState.ringfencedFiltered = [];
      _toolState.ringfencedTypes = [];
      _toolState.disableBoundary = false;
    } else {
      const filterAllGeometryAssets = () => {
        return allGeometryAssets.filter((p) => {
          if (p.asset.styles.name === _selected) {
            return false;
          }
          if (groupedTypes[_selected]) {
            return !groupedTypes[_selected].includes(p.asset.styles.name);
          }
          return true;
        });
      };

      const selectedAssets = drawBoundary
        ? getRingfencedNetworkAssets(filterAllGeometryAssets(), drawBoundary)
        : filterAllGeometryAssets()
            .filter((item1) => _ringfenced.some((item2) => item1.asset.id === item2.id))
            .map((m) => m.asset);

      const ringfenced = refineRingfencedAssets(cables, selectedAssets);
      const ringfencedTypes = getRingfencedTypes(ringfenced);
      _toolState.ringfenced = ringfenced;
      _toolState.ringfencedTypes = ringfencedTypes;
      setPendingAssetRefesh(true);
    }
    setToolState(_toolState);
    setSelected("");
    document.getElementById("wrapper").focus();
  };

  const refreshRingfencedAssets = (skipRefine, customSelected = undefined) => {
    if (!customSelected) {
      customSelected = selected;
    }
    const selectedAssets = drawBoundary
      ? getRingfencedNetworkAssets(allGeometryAssets, drawBoundary)
      : allGeometryAssets
          .filter((item1) => ringfenced.some((item2) => item1.asset.id === item2.id))
          .map((m) => m.asset);

    const _ringfenced = skipRefine
      ? [...selectedAssets]
      : refineRingfencedAssets(cables, selectedAssets);
    const ringfencedTypes = getRingfencedTypes(_ringfenced);

    const _toolState = toolState;
    _toolState.ringfenced = _ringfenced;
    _toolState.ringfencedTypes = ringfencedTypes;
    _toolState.ringfencedFiltered = filterAssetList(
      customSelected,
      _ringfenced,
      groupedTypes[customSelected] !== undefined,
    );
    _toolState.showResults = false;
    _toolState.errors = {};
    setToolState(_toolState);
  };

  const updateNetwork = (asset, assetType) => {
    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    const _formState = { ...formState };
    _formState.network.existing = false;
    if (assetType === "welders") {
      dispatchRedux(deleteWelder(asset));
    } else if (assetType === "motors") {
      dispatchRedux(deleteMotor(asset));
    } else if (assetType === "pointOfConnections") {
      dispatchRedux(deletePointOfConnection(asset));
    } else if (assetType === "transformers") {
      dispatchRedux(deleteTransformer(asset));
    } else if (assetType === "groupedConnections") {
      dispatchRedux(deleteGroupedConnection(asset));
    } else if (assetType === "nodes") {
      dispatchRedux(deleteNode(asset));
    } else if (assetType === "connectionPoints") {
      dispatchRedux(deleteConnectionPoint(asset));
    } else if (assetType === "cables") {
      dispatchRedux(deleteCable(asset));
    } else {
      console.error(
        `Unexpected type found.  Current type: ${assetType}.  Expected: welders, motors, pointOfConnections, transformers, groupedConnections, nodes, connectionPoints, cables`,
      );
    }

    dispatch({
      form: "network",
      obj: _formState.network,
      type: "REPLACE_STATE",
    });

    refreshRingfencedAssets();
  };

  const canDeleteRingfencedNodes = () => {
    switch (selected) {
      case "Node":
      case "Welder":
      case "Motor":
      case "Transformers":
      case "Pole-mounted Transformer":
      case "Ground-mounted Transformer":
        return !ringfenced.some((c) => c.styles.type === "cable");
      default:
        return true;
    }
  };

  return (
    <>
      {confirmationMessage && (
        <WarningModal
          item={true}
          action={applyChanges}
          msg={"Would you like to continue the update?"}
          messages={confirmationMessage}
          yesLabel="Yes"
          dismissLabel="No"
        />
      )}
      {mode === "remove-group" && ringfenced.length === 0 && toolState.disableBoundary && (
        <div className="mx-4 my-4">
          No assets available to delete. Nodes cannot be deleted without also removing connected
          cable(s)
        </div>
      )}
      {ringfencedAssets.map((assetGroup, i) => (
        <GroupAsset
          key={`GroupAsset_${i}`}
          asset={assetGroup}
          selectedAsset={selected}
          filterAssets={filterAssets}
        ></GroupAsset>
      ))}
      {ringfenceUpdated && (
        <div className="text-warning px-4 py-2">
          <FontAwesomeIcon icon={faInfoCircle} /> Please Run Assessment to refresh connection counts
        </div>
      )}
      {ringfencedTypes.length > 0 && (
        <ListGroupItem
          key="all"
          tag="a"
          className={`d-flex justify-content-between ${
            selected === "All" ? "text-white" : "text-black"
          }`}
          onClick={() => filterAssets("All")}
        >
          <span>
            <span>All</span>
            <span className="badge badge-primary ml-1" style={{ fontSize: "100%" }}>
              {ringfencedAssetCount}
            </span>
          </span>
          <span className="list-group-item-icon">
            <i className={`icon-${selected === "All" ? "check" : "cross"}-solid`}></i>
          </span>
        </ListGroupItem>
      )}
      {ringfencedTypes.length > 0 && mode === "select-group" && (
        <>
          {shouldShowStatusForm(clientSettings, selected, ringfencedTypes) && (
            <StatusUpdate
              minimised={minimisedStatus}
              setMinimised={setMinimisedStatus}
              value={status}
              setValue={setStatus}
            ></StatusUpdate>
          )}
          {shouldShowLinkBoxStatusForm(clientSettings, selected, ringfencedFiltered) && (
            <StatusNodeUpdate
              title="Link Box Status"
              type="linkBoxStatus"
              minimised={minimisedLinkBoxStatus}
              setMinimised={setMinimisedLinkBoxStatus}
              value={linkBoxStatus}
              setValue={setLinkBoxStatus}
            ></StatusNodeUpdate>
          )}
          {shouldShowPotEndStatusForm(clientSettings, selected, ringfencedFiltered) && (
            <StatusNodeUpdate
              title="Pot End Status"
              type="potEndStatus"
              minimised={minimisedPotEndStatus}
              setMinimised={setMinimisedPotEndStatus}
              value={potEndStatus}
              setValue={setPotEndStatus}
            ></StatusNodeUpdate>
          )}
          {shouldShowPoleStatusForm(clientSettings, selected, ringfencedFiltered) && (
            <StatusNodeUpdate
              title="Pole Status"
              type="poleStatus"
              minimised={minimisedPoleStatus}
              setMinimised={setMinimisedPoleStatus}
              value={poleStatus}
              setValue={setPoleStatus}
            ></StatusNodeUpdate>
          )}
          {shouldShowPhasesForm(ringfencedTypes, selected) && (
            <PhaseUpdate
              minimised={minimisedNumberOfPhases}
              setMinimised={setMinimisedNumberOfPhases}
              value={numberOfPhases}
              setValue={setNumberOfPhases}
              valueWarning={numberOfPhasesWarning}
              selected={selected}
            ></PhaseUpdate>
          )}
          {shouldShowCableTypeForm(ringfencedTypes, selected) && (
            <CableTypeUpdate
              minimised={minimisedCableType}
              setMinimised={setMinimisedCableType}
              value={cableType}
              setValue={setCableType}
              valueWarning={cableTypeWarning}
              selected={selected}
              status={status}
              reference={reference}
              ohWhitelist={ohWhitelist}
              ugWhitelist={ugWhitelist}
              ringfencedTypes={ringfencedTypes}
            ></CableTypeUpdate>
          )}
          {shouldShowConsumerForm(ringfencedTypes, selected) && (
            <ConsumerUpdate
              minimised={minimisedConsumers}
              setMinimised={setMinimisedConsumers}
              consumers={consumers}
              setConsumers={setConsumers}
              reference={reference}
              localOverrideConfig={localOverrideConfig}
            ></ConsumerUpdate>
          )}
          {shouldShowSubGroupForm(ringfencedTypes, selected) && (
            <SubConsumerUpdate
              minimised={minimisedSubGroup}
              setMinimised={setMinimisedSubGroup}
              subGroups={subGroups}
              setSubGroups={setSubGroups}
              reference={reference}
              localOverrideConfig={localOverrideConfig}
            ></SubConsumerUpdate>
          )}
          {
            <div className="form-body">
              <Button
                id="confirm-update"
                color="success"
                block
                onClick={confirmChanges}
                className={"confirm-button " + (showUpdatedMessage ? "updated-button" : "")}
                disabled={!hasPendingChange() || cableTypeWarning || numberOfPhasesWarning}
              >
                {showUpdatedMessage ? (
                  <>
                    <span>
                      <i className="icon-check-solid"></i>
                    </span>
                    Updated
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>
            </div>
          }
        </>
      )}
      {mode === "remove-group" && selected && (
        <div className="form-body">
          <Button
            color="danger"
            block
            onClick={() => deleteAssets()}
            disabled={!canDeleteRingfencedNodes()}
          >
            Delete
          </Button>
        </div>
      )}
    </>
  );
};

export default GroupUpdate;
