import { useContext, useState, useEffect } from "react";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";

import { RootState } from "../app/store";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import PopupResultsMarker from "./PopupResultsMarker";
import "./Asset.css";

const PopupResults = ({ asset, iconSize }: { asset: any; iconSize: number }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const popupResults = useSelector((state: RootState) => state.settings.isPopupResultsEnabled);

  const { mode, showResults, assessmentFailed, clickedFaultDetectionCable, popupResultsAssetId } =
    toolState;

  const { AssessmentResultsPopupEnabled } = clientSettings.Features;

  useEffect(() => {
    if (!showResults || assessmentFailed) {
      setShowResultsPopup(false);
    }
  }, [showResults, assessmentFailed]);

  useEffect(() => {
    if (clickedFaultDetectionCable === asset.id) {
      toggleResultsPopup();
    }
  }, [clickedFaultDetectionCable]);

  useEffect(() => {
    if (popupResultsAssetId === asset.id) {
      toggleResultsPopup();
      const _toolState = { ...toolState };
      _toolState.popupResultsAssetId = null;
      setToolState(_toolState);
    }
  }, [popupResultsAssetId]);

  const toggleResultsPopup = () => {
    if (clickedFaultDetectionCable === asset.id) {
      const _toolState = { ...toolState };
      _toolState.clickedFaultDetectionCable = null;
      setToolState(_toolState);
    }

    if (showResultsPopup) {
      setShowResultsPopup(false);
    } else if (
      AssessmentResultsPopupEnabled &&
      popupResults &&
      mode === "select-results" &&
      showResults &&
      !assessmentFailed
    ) {
      setShowResultsPopup(true);
    }
  };

  const getPopupPosition = (asset: any) => {
    if (asset.geometry.lat) {
      return asset.geometry;
    }
    // Midpoint of cable segment
    var latDiff = asset.geometry[1].lat - asset.geometry[0].lat;
    var lngDiff = asset.geometry[1].lng - asset.geometry[0].lng;
    return { lat: asset.geometry[0].lat + latDiff / 2, lng: asset.geometry[0].lng + lngDiff / 2 };
  };

  return (
    <>
      {showResultsPopup && (
        <Marker
          key={`popup-results-${asset.id}`}
          position={getPopupPosition(asset)}
          icon={PopupResultsMarker(asset, iconSize)}
          eventHandlers={{
            click: () => {
              toggleResultsPopup();
            },
          }}
        />
      )}
    </>
  );
};

export default PopupResults;
