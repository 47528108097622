import L from "leaflet";
import { useContext } from "react";
import { Button } from "reactstrap";
import { renderToString } from "react-dom/server";
import { FormContext } from "../context/FormContext";
import { parseResultsForPopup } from "../utils/parseResults";
import { Table } from "reactstrap";
import { isNode } from "../utils/networkFunctions";
import "./DetectTooltip.css";
import "./Asset.css";

export const PopupResultsMarker = (asset: any, iconSize: number) => {
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;

  let results = parseResultsForPopup(asset, clientSettings);

  let marginLeft = -42;
  if (asset.styles.tool === "Marker") {
    if (isNode(asset)) {
      marginLeft = -37;
    } else if (iconSize === 1) {
      marginLeft = -38;
    } else if (iconSize === 2) {
      marginLeft = -30;
    } else if (iconSize === 3) {
      marginLeft = -20;
    }
  }
  // Gap between arrowhead and asset:
  marginLeft += 4;

  const icon = L.divIcon({
    className: "",
    iconAnchor: [0, 0],
    html: renderToString(
      <div style={{ marginTop: -10, marginLeft: marginLeft }}>
        <div className="Tooltip-Wrapper">
          <div className="Tooltip-Tip right">
            <>
              <div className="pr-3">
                {results.length ? (
                  <Table className="tablePopup">
                    {results.map((result) => (
                      <tr>
                        <td className="px-2 py-1">
                          <span className={`text-${result.class}`}>{result.name}</span>
                        </td>
                        <td className="px-2 py-1">
                          {result.value && (
                            <span className={`text-${result.class}`}>{result.value}</span>
                          )}
                          {result.unit && (
                            <span className={`text-${result.class} tablePopupUnit`}>
                              {result.unit}
                            </span>
                          )}
                          {result.constraint && (
                            <span className="bg-danger text-white px-1">!</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </Table>
                ) : (
                  <div>
                    <span className="tablePopupPlaceholder p-2">
                      Make a selection in the sidebar results tab to see results here
                    </span>
                  </div>
                )}
              </div>
              <div>
                <Button className="tablePopupCloseButton" id="closeBtn" size="sm" color="danger">
                  <div className="icon-cross-solid"></div>
                </Button>
              </div>
            </>
          </div>
        </div>
      </div>,
    ),
  });

  return icon;
};

export default PopupResultsMarker;
